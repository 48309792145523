import InfoIcon from '@mui/icons-material/Info';
import { ClickAwayListener, IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
export default function CopyrightTooltip({
  text,
  children,
  sx = {},
  ...props
}) {


  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = (e) => {
    setOpen(!open);
    e.stopPropagation()
  };

  if(text == null || text == ""|| text == undefined){
    return null;
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "common.black",
                "& .MuiTooltip-arrow": {
                  color: "white",
                },
              },
            },
          }}
          title={
            <Typography variant="body2" sx={{ color: "white" }}>
              {text}
            </Typography>
          }
          {...props}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <IconButton className='urbnups-copyright-icon' onClick={handleTooltipOpen} sx={{  ...sx }}>
           <InfoIcon />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
