"use client";
import { Box } from "@mui/material";
import {
  ColorScheme,
  LoadPriority,
  Map,
  Marker,
  PointOfInterestCategory
} from "mapkit-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PlaceMiniMapShowMapButton from "./PlaceMiniMapShowMapButton";
const mapkitJSToken=window.urbnups?.appleMapKitToken||"eyJraWQiOiI4SDg3Mjk2V1lNIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJMS0tBOFREQlZWIiwiaWF0IjoxNzMzMTM2MzQyLCJvcmlnaW4iOiJ3d3cuZW50ZGVja2UtbmRzLmRlIn0.ToeF78TLa7Bu9-a0E6ydOLzstzIKtU-dw4MaqanxWxLVh7P0DQ2x5TzM6A_nAerJRvxy97Um8La9vmO80Dfijg"
function PlaceMiniMap({  place=null}) {

  const { t } = useTranslation();

  const initialRegion = useMemo(
    () => ({
      centerLatitude: place.location?.lat,
      centerLongitude: place.location?.long,
      latitudeDelta: 0.01,
      longitudeDelta: 0.01,
    }),
    [place]
  );




  return (
    <Box sx={{ overflow: "hidden", height: "300px", width: "100%", position: "relative" ,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    }}>
    <Box
      sx={{
      
        overflow: "hidden",
        height: "300px",
        mt: {
          xs: 2,
          md: 0,
        },
        width:{
          xs: "calc(100% - 32px)",
          
          md:"100%"
        },
        position: "relative",
        borderRadius:{
          xs: "8px",
          md: "0px",
        },
      }}
    >
    <Map
        colorScheme={ColorScheme.Light}
        loadPriority={LoadPriority.PointsOfInterest}
        showsUserLocationControl={true}
        showsPointsOfInterest={true}
        showsUserLocation={false}
        includedPOICategories={[
          PointOfInterestCategory.Park,
          PointOfInterestCategory.AmusementPark,
          PointOfInterestCategory.Beach,
          PointOfInterestCategory.Campground,
          PointOfInterestCategory.Library,
          PointOfInterestCategory.Marina,
          PointOfInterestCategory.Museum,
          PointOfInterestCategory.NationalPark,
          PointOfInterestCategory.Stadium,
          PointOfInterestCategory.Theater,
          PointOfInterestCategory.Zoo,
        ]}
        showsMapTypeControl={false}
        initialRegion={initialRegion}
       
       token={mapkitJSToken}
      >
        <Marker
          selected={false}
          title={ place.name}
          color="#FF8243"
          latitude={place.location?.lat}
          longitude={place.location?.long}
        />
        
    
      </Map>
      <PlaceMiniMapShowMapButton place={place} title={t("place.navigation")} />
     
      
    </Box>
    </Box>
  );
}
export default PlaceMiniMap;
