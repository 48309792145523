import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Divider, Grid, IconButton, Link, Typography } from "@mui/material";
import React from "react";
import LinkButton from "../buttons/LinkButton";
import PlaceMiniMap from "../maps/PlaceMiniMap";
import OpeningHours from "../openinghours/OpeningHours";
import { useTranslation } from "react-i18next";

function POIInfo({ place }) {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
      <PlaceMiniMap place={place} />
      </Grid>
      <Grid item xs={12} md={6} sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: {
          xs: "flex-start",
          md: "center",
        },
        alignItems: {
          xs: "flex-start",
          md: "center",
        },

      }}>
      <Box p={2}>
        {
          /* Address */

          place?.formattedAddress && (
            <>
              <Box mb={2}>
              <Typography sx={{
                fontWeight: "bold",
              }} variant="body1">
                  {place?.name}
                </Typography>
                <Typography variant="body1">
                  {place?.formattedAddress}
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
            </>
          )
        }

        {
          /* Öffnungszeiten (Opening Times) */
          (place?.businessStatus == "CLOSED_TEMPORARILY" ||
            place?.businessStatus == "CLOSED_PERMANENTLY") && (
            <>
              <Box display="flex" alignItems="center" mb={2}>
                
                <Typography variant="body1" color="error">
                  {t(
                    place?.businessStatus == "CLOSED_TEMPORARILY"
                      ? "opening_hours.CLOSED_TEMPORARILY"
                      : place?.businessStatus == "CLOSED_PERMANENTLY"
                      ? "opening_hours.CLOSED_PERMANENTLY"
                      : "opening_hours.NoHoursAvailable"
                  )}
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
            </>
          )
        }

        {
          /* Öffnungszeiten (Opening Times) */
          place?.businessStatus == "OPERATIONAL" && place?.openingHours && (
            <>
              <Box display="flex" >
              
                <OpeningHours
                  headline={t("opening_hours.general_opening_hours")}
                  openingHours={place?.openingHours}
                />
              </Box>
              <Divider sx={{ my: 2 }} />
            </>
          )
        }
        {
          /* Phone */

          place?.phone && (
            <>
              <Box display="flex" alignItems="center" mb={2}>
                <IconButton>
                  <PhoneIcon color="primary" />
                </IconButton>
                <Typography variant="body1">
                  <Link href="tel:+4315871417" underline="none">
                    {place?.phone}
                  </Link>
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
            </>
          )
        }

        {/* Website */}
        {place?.links != null &&
          place?.links
            .filter((link) => link != null && link != "")
            .map((link, index) => (
              <Box display="flex" alignItems="center" mb={2}>
                 <IconButton>
                  <LanguageIcon color="primary" />
                </IconButton>
               
                <Typography variant="body1">
                <LinkButton key={index} url={link} />
                  </Typography>
              </Box>
            ))}
      
        {
          /* Email */
          place?.email && (
            <>
            <Divider sx={{ my: 2 }} />
              <Box display="flex" alignItems="center" mb={2}>
                <IconButton>
                  <EmailIcon color="primary" />
                </IconButton>
                <Typography variant="body1">
                  <Link href={"mailto:" + place?.email} underline="none">
                    {place?.email}
                  </Link>
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
            </>
          )
        }
      </Box>
      </Grid>
    </Grid>
  );
}

export default POIInfo;
