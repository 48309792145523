import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFetch } from "use-http";
import POIMediaSlider from "./components/mediaslider/POIMediaSlider";
import POIDescription from "./components/poi/POIDescription";
import POIEvents from "./components/poi/POIEvents";
import POIHighlights from "./components/poi/POIHighlights";
import POIInfo from "./components/poi/POIInfo";
import POIQuestions from "./components/poi/POIQuestions";
import { useTranslation } from "react-i18next";
import TrackingHelper from "./utils/TrackingHelper";

export const SinglePlaceModal = () => {
  const partnerId = window.urbnups.partnerId;
  const language = window.urbnups.language;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [modalOpen, setModalOpen] = useState(false);
  const [place, setPlace] = useState(null);
  const [zIndex, setZIndex] = useState(111111001);
  const {t} = useTranslation();
  const { get, loading, data, error } = useFetch(
    "https://urbnups.com/widgets.api"
  );

  const onClose = () => {
    setModalOpen(false);
    //setPlaceId(null);
    window.urbnups.maxZIndex = (window.urbnups?.maxZIndex || 111111001) - 1;
  };

  useEffect(() => {
    if (data) {
      console.log("data", data);
      setPlace(data);
      TrackingHelper.trackPlaceView(data);
    }
  }, [data]);

  const handlePopState = (event) => {
    //alert("handlePopState");
    setModalOpen(false);
  };

  useEffect(() => {
    window.addEventListener("message", (ev) => {
      const event = ev.data;

      if (event.type === "urbnups-show-place") {
        console.log("receive urbnups-show-place", event.data);
        console.log("vent?.data?.source", event?.data?.source);
        var newZIndex = (window.urbnups.maxZIndex || 111111001) + 1;
        setZIndex(newZIndex);
        window.urbnups.maxZIndex = newZIndex;
        // setPlaceId(event.data?.placeId);
        setPlace(null);
        // window.history.pushState({ modalOpen: true }, "", window.location.href);
        if(event?.data?.source === "datahub_pois")
          {
            get(
              `datahub_poi/${event.data?.key}?lang=${language}&partnerId=${partnerId}`
            );
          }
        else if(event.data?.googlePlaceId !== null)
        {
          get(
            `place/${event.data?.googlePlaceId}?lang=${language}&partnerId=${partnerId}`
          );
        }
        else{
          setPlace(null);
          //TODO: show error message
          setModalOpen(false);
        } 
       
        setModalOpen(true);
        window.addEventListener("popstate", handlePopState);
      }
    });

    return () => {
      window.removeEventListener("message", () => {});
    };
  }, [get, language, partnerId]);

  return (
    <Dialog
      open={modalOpen}
      fullScreen={!isDesktop}
      maxWidth="md"
      sx={{ zIndex: zIndex }}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent
        sx={{
          p: 0,
          width: isDesktop ? "900px" : "100%",
          height: isDesktop ? 'calc(100vh * 0.8)' : "100%",
          flexGrow: 1,
        }}
      >
        {" "}
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            position: "absolute",

            p: 1,
            zIndex: 100,
            //align vertical
          }}
        >
          {onClose && (
            <Box>
              <IconButton className="urbnups-backButton" onClick={onClose}>
                <ArrowBackIosNewIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        {/*
  <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            position: "absolute",
            right: 10,
            p: 1,
            zIndex: 100,
            //align vertical
          }}
        >
          <LikeButton placeId={place?.place_id} />
        </Box>

          */}
        {loading && (
          <>
            <Box
              sx={{
                padding: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

              }}
            >
              <CircularProgress className="urbnups-loadingindicator" />
            </Box>
          </>
        )}
        {error &&
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pt:5,
          pb:5
        }}>
         <Typography variant="h5" color="error">{t("place.error")}</Typography>

        </Box>

        }
        {!loading && !error && place != null && (
          <Box
            className="urbnups-poi-modal"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            
            }}
          >
            <POIMediaSlider place={place} />
          
            <POIDescription place={place} />
        
            <POIHighlights place={place} />
            <POIEvents place={place} />
            {
            (!place?.events?.length && !place?.urbnupsMoments?.length) && 
             <Box sx={{
              mt:2
             }}/>
            }
            <POIInfo place={place} />
            <POIQuestions place={place} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
