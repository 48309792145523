import { Box, CircularProgress } from "@mui/material";
import { useEffect, useMemo, useRef, useCallback } from "react";

const ChatIframe = ({ url, userId,sessionId ,setChatLoaded}) => {
  const iframeRef = useRef(null);

  // Cache the iframe URL using useMemo
  const iframeUrl = useMemo(() => {
    if (!userId || !url) return null;
    return `${url}${url.includes("?") ? "&" : "?"}userId=${userId}&sessionId=${sessionId}`;
  }, [url, userId]);

  // Memoized style to prevent re-renders
  const iframeStyle = useMemo(
    () => ({ width: "100%", height: "100%", border: "none" }),
    []
  );

  // Handle iframe load with useCallback to prevent unnecessary recreations
  const handleIframeLoad = useCallback(() => {
    console.log("Iframe loaded");
    window.urbnups = {
      ...window.urbnups, // Preserve existing properties in urbnups
      chatIframeRef: iframeRef.current // Add or update chatIframeRef
    };
    setChatLoaded(true);
  }, []);

  if (!iframeUrl) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress className="urbnups-loadingindicator" />
      </Box>
    );
  }

  return (
    <iframe
      ref={iframeRef}
      title="Chat Window"
      allow="microphone;autoplay;geolocation"
      onLoad={handleIframeLoad}
      src={iframeUrl}
      style={iframeStyle}
    />
  );
};

export default ChatIframe;