import { Button, Link, Typography } from "@mui/material";
import React from "react";
import { Img } from "react-image";

const extractHostname = (url) => {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url == null) return "";

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
};
const extractRootDomain = (url) => {
  var domain = extractHostname(url),
    splitArr = domain.split("."),
    arrLen = splitArr.length;

  //extracting the root domain here
  //if there is a subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
    //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
      //this is using a ccTLD
      domain = splitArr[arrLen - 3] + "." + domain;
    }
  }
  return domain;
};

export const LinkButton = ({ url }) => {
//  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    event.preventDefault();
    window.open(url, "_blank");
//setOpen(true);
  };

 // const handleClose = () => setOpen(false);

  return (
    <>
      <Link
       
        component={"a"}
        sx={{
      //    backgroundColor: "#f8f9fa",
       //   color: "#000000",
         
        }}
       
        onClick={handleOpen}
      >
        <Typography className="urbnups-place-link">{extractRootDomain(url)}</Typography>
      </Link>
      {
// <BrowserModal open={open} title={extractRootDomain(url)} handleClose={handleClose} iframeSrc={url} />
      }
     
    </>
  );
};

export default LinkButton;
