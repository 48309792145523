import { keyframes } from "@emotion/react";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ResizeHelper from "../../utils/ResizeHelper";
import CopyrightTooltip from "../copyright/CopyrightTooltip";
const POIMediaSlider = ({ place }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState({}); // Map to track loaded images
  const media = useMemo(() => place?.media || [], [place]);
  useEffect(() => {
    // Preload all images and update their loading status
    media.forEach((slide, index) => {
      const img = new Image();
      img.src = ResizeHelper.resizeImage(slide.url, 900);
      img.onload = () => {
        setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
      };
      img.onerror = () => {
        setLoadedImages((prevState) => ({ ...prevState, [index]: false }));
      };
    });
  }, [media]);

  useEffect(() => {
    let timeout;

    if (loadedImages[currentIndex]) {
      const nextIndex = (currentIndex + 1) % media.length;

      const proceedToNextSlide = () => {
        if (loadedImages[nextIndex]) {
          // Next image is loaded; proceed to next slide
          setCurrentIndex(nextIndex);
        } else {
          // Wait until the next image is loaded
          timeout = setTimeout(proceedToNextSlide, 500);
        }
      };

      // Set a timeout to change the slide after 5 seconds
      timeout = setTimeout(proceedToNextSlide, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [currentIndex, loadedImages, media.length]);

  const progressAnimation = keyframes`
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  `;

  const handleClick = (event) => {
    const { clientX } = event;
    console.log("event.currentTarget", event.currentTarget);
    const { left, width } = event.currentTarget.getBoundingClientRect();
    const x = clientX - left;

    if (x < width / 2) {
      goToPreviousSlide();
    } else {
      goToNextSlide();
    }
  };

  const goToPreviousSlide = () => {
    const prevIndex = (currentIndex - 1 + media.length) % media.length;
    setCurrentIndex(prevIndex);
  };

  const goToNextSlide = () => {
    const nextIndex = (currentIndex + 1) % media.length;
    setCurrentIndex(nextIndex);
  };

  if (!media || media.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "relative",
        height: {
          xs:
            place?.thumbnailFallback === true
              ? "calc(300px)"
              : "calc(100vh * 0.8)",
          md:
            place?.thumbnailFallback === true
              ? "calc(300px)"
              : "calc(100dvh * 0.4)",
        },
        overflow: "hidden",
      }}
    >
      {/* Slide Container */}
      {media.map((slide, index) => {
        const isCurrent = currentIndex === index;
        const isLoaded = loadedImages[index];

        return (
          <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage: isLoaded
              ? `url(${ResizeHelper.resizeImage(slide.url, 900)})`
              : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: isCurrent ? "block" : "none",
            transition: "opacity 1s ease-in-out",
            backgroundColor: "black", // Fallback background color
            cursor: "pointer",
            pointerEvents: "auto", // Allow interactions on the whole box
          }}
          onClick={isCurrent ? handleClick : undefined}
        >
            {isCurrent && !isLoaded && (
              // Loading Spinner
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            {isLoaded && isCurrent && (
              // Overlay Components
              <>
                <Box
                  sx={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    variant="contained"
                    className="urbnups-backButton"
                    onClick={() => window.open(slide.url, "_blank")}
                  >
                    <CenterFocusWeakIcon
                      sx={{
                        color: "white",
                      }}
                    />
                  </IconButton>
                </Box>
                <Box
  className="urbnups-copyright-tooltip"
  sx={{
    position: "absolute",
    bottom: "15px",
    right: "10px",
    display: "flex",
    alignItems: "center",
    zIndex: (window.urbnups?.maxZIndex + 100 + index),
    pointerEvents: "auto", // Enable interactions on the tooltip
  }}
>
  <CopyrightTooltip
    text={`${slide.copyrightName} ${slide?.license || ""}`}
  />
</Box>
              </>
            )}
          </Box>
        );
      })}

      {/* Title */}
      <Box className="urbnups-poi-modal-namebox">
        <Typography className="urbnups-poi-banner-headline" component="div">
          {place?.name}
        </Typography>
        {place?.orgName &&
  <Typography  className="urbnups-poi-banner-subheadline"  component="div">
        {place?.orgName}
        </Typography>

          }
      </Box>

      {/* Progress Bars */}
      <Box
        sx={{
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          gap: "5px",
          width: "90%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "5px",
          }}
        >
          {media.length > 1 &&
            media.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  height: "2px",
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width:
                      currentIndex > index
                        ? "100%"
                        : currentIndex === index && loadedImages[currentIndex]
                        ? "0%"
                        : "0%",
                    height: "100%",
                    backgroundColor: "white",
                    ...(currentIndex === index &&
                      loadedImages[currentIndex] && {
                        animation: `${progressAnimation} 5s linear forwards`,
                      }),
                  }}
                />
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default POIMediaSlider;
