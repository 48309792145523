import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, Chip, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const RegionChatCTA = ({ questions, placeholder, headline,subline, region = null }) => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const onSendChatMessage = (message, audio = false) => {
    window.postMessage(
      {
        type: "urbnups-chat-show",
        data: null,
      },
      "*"
    );
    console.log("window urbnups", window.urbnups);

    window.postMessage(
      {
        type: "urbnups-chat-sendMessage",
        data: {
          message: message,
          audio: audio,
        },
      },
      "*"
    );
  };

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p:0,
        mt:2,
        mb:2,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={6} sx={{
          justifyContent:"center",
          alignItems:"center",
          display:"flex",
          border:"1px solid #c4cec9",
          background:"url('https://static.urbnups.app/travel_assistant/NDS/chat_CTA_L1Dq7_Bi5.jpg')",
          backgroundSize:"cover",
          backgroundPosition:"center",
          backgroundRepeat:"no-repeat"

        }}>
       
          </Grid>
          <Grid item xs={12} md={6}
          className="urbnups-cta-background"
          sx={{
          
            p:{
              xs:2,
              md:3
            }
          }}
          >
   <Typography
          className="urbnups-region-cta-headline"
          sx={{ marginBottom: "20px", textAlign: "center" }}
        >
          {headline}
        </Typography>
       
        <Typography
          className="urbnups-region-cta-subline"
          sx={{ marginBottom: "20px", textAlign: "center" }}
        >
          {subline}
        </Typography>
        {/* Search Input */}
        <TextField
          fullWidth
          value={search}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              // Do code here
              ev.preventDefault();
              onSendChatMessage(t("region_chat_CTA.questionTemplate", {regionName: region.name, question: search}));
            }
          }}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholder}
          InputProps={{
            startAdornment: <SearchIcon />,
            endAdornment: (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  m:0,
                  height: "100%",
                }}
                onClick={() => {
                  //  onSendChatMessage(regionName ? regionName + ": " +search: search);
                  onSendChatMessage(t("region_chat_CTA.questionTemplate", {regionName: region.name, question: search}));
                }}
              >
                <SendIcon
                  sx={{
                    color: "white",
                  }}
                />
              </Button>
            ),
          }}
      
          sx={{
            marginBottom: "20px",
            backgroundColor: "#fff",
            
            border: "none",
            "& .MuiOutlinedInput-root": {
             paddingBottom:"0px",
             paddingTop:"0px"
            }
          }}
        />

        {/* Questions List */}
        <Stack direction="row" flexWrap="wrap" justifyContent="left" gap={1}>
          {questions.map((question, index) => (
            <Chip
              onClick={() => {
                onSendChatMessage(question, false);
              }}
              key={index}
              label={question}
              sx={{
                backgroundColor: "#f3f7fc",
                color: "#004A7C",
                borderRadius: "15px",
                textWrap: "wrap",
                height: "auto",
                "& .MuiChip-label": {
                  display: "block",
                  whiteSpace: "normal",
                },

                padding: "10px",
                "&:hover": {
                  backgroundColor: "#dbe8f4",
                },
              }}
            />
          ))}
        </Stack>
          </Grid>
      </Grid>
   
    </Container>
  );
};

export default RegionChatCTA;
