import React from "react";
import { Box, IconButton, Typography, Link } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useFetch } from "use-http";
import SingleRegionSlider from "../SingleRegionSlider";

const partnerId = window.urbnups.partnerId;
const language = window.urbnups.language;

const RegionsGrid = ({
  parentregionid = null,
  headline,
  subline,
  showMore = false,
  moreLabel = "more",
  moreLink = null,
  baseurl = "",
  type = null
}) => {
  const {
    loading,
    error,
    data = { region: null, subregions: [] },
  } = useFetch(
    `https://urbnups.com/widgets.api/region/${parentregionid}/subregions?lang=${language}&partnerId=${partnerId}`
    + (type != null ? "&type=" + type : ""),
    [parentregionid, language, partnerId, type]
  );

  const onShowRegion = (region) => {
    if (baseurl != "") {
      if (baseurl.indexOf("?") > -1)
        window.location.href = baseurl + "&regionid=" + region.key;
      else
        window.location.href = baseurl + "?regionid=" + region.key;
    } else {
      window.postMessage(
        {
          type: "urbnups-show-region",
          data: region,
        },
        "*"
      );
    }
  };

  return (
    <Box className="urbnups-regiongridContainer">
      {/* Header with headline and optional "More" button */}
      <Box sx={{ display: "flex", alignContent: "center", alignItems: "center" }}>
        {headline && (
          <Link href={moreLink}>
            <Typography
              variant="h3"
              sx={{
                color: "black",
                whiteSpace: "normal",
                fontWeight: 600,
                ml: { xs: 3, md: 0 },
                mr: { xs: 3, md: 0 },
              }}
            >
              {headline}
            </Typography>
          </Link>
        )}

        {showMore && (
          <Box sx={{ display: "flex", marginLeft: "auto", pr: 1 }}>
            <IconButton
              href={moreLink}
              sx={{
                backgroundColor: "rgba(255,255,255,1)",
                borderRadius: 30,
                pl: 2,
                color: "white",
                "&:hover": {
                  backgroundColor: "#FF8243",
                  color: "white",
                },
              }}
            >
              <Typography sx={{ color: "#1c1c1c" }} variant="subtitle2">
                {moreLabel}
              </Typography>
              <ArrowForwardIosIcon sx={{ color: "#1c1c1c", height: 15 }} />
            </IconButton>
          </Box>
        )}
      </Box>

      {/* Subline */}
      {subline != null && (
        <Typography
          variant="subtitle2"
          sx={{
            mt: 0.2,
            lineClamp: 1,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
            WebkitLineClamp: "2",
            ml: { xs: 3, md: 0 },
            mr: { xs: 3, md: 0 },
          }}
        >
          {subline}
        </Typography>
      )}

      {/* Grid Content */}
      <Box 
        sx={{
          display: "flex",
          flexWrap: "wrap",
     
          
          width: "100%",
        }}
      >
        {!loading &&
          data?.subregions?.filter((region) => region?.thumbnail != null).map((region, index) => (
            <SingleRegionSlider
              key={region?.key}
              index={index}
              onShowRegion={onShowRegion}
              region={region}
            />
          ))}
      </Box>
    </Box>
  );
};

export default RegionsGrid;