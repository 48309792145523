import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const POIDescription = ({ place }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (
  (  place?.description === null ||
    place?.description === undefined ||
    place?.description === "")&&(place?.teaser === null ||
    place?.teaser === undefined ||
    place?.teaser === ""
    )
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        maxWidth: "600px",
        margin: "0 auto",
        textAlign: "center",
        pt:2,
        pl:2,
        pr:2,
        position: "relative",
      }}
    >
         {place?.teaser != null &&
         <>
      <Typography className="urbnups-place-teaser" component="div">
        {place?.teaser}
      </Typography>
   

   
      <Divider
        sx={{
          mt: 1,
          mb: 1,
        }}
      />  </> }
      {/* Description text */}
      <Typography
        variant="body1"
        sx={{
          maxHeight: expanded ? "none" : "70px", // Limit height when not expanded
          overflow: "hidden",
          position: "relative",
          transition: "max-height 0.3s ease",
          whiteSpace: "normal", // Allow text to wrap
        }}
      >
        {place?.description}
      </Typography>

      {/* Gradient overlay for fade effect */}
      {!expanded && (
        <Box
          sx={{
            position: "absolute",
            bottom: "70px", // Position above the button
            left: 0,
            right: 0,
            height: "20px", // Height of the gradient overlay
            background: "linear-gradient(to bottom, transparent, white)", // Gradient effect
          }}
        />
      )}

      {!expanded && (
        <Button
          onClick={handleExpandClick}
          variant="contained"
          sx={{
            mt: 2,
           
          }}
        >
          {t(
            expanded ? "place.descriptionShowLess" : "place.descriptionShowMore"
          )}
        </Button>
      )}
      {expanded && place?.fullUrl != null && (
        <Button
          href={place?.fullUrl}
          variant="outlined" sx={{ mt:2,

            borderColor: "#2f534f",
            color: "#2f534f",
           }}
        >
          {t("place.showFullDataset")}
        </Button>
      )}
    </Box>
  );
};

export default POIDescription;
