import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import ResizeHelper from "../../../utils/ResizeHelper";
import CopyrightTooltip from "../../copyright/CopyrightTooltip";

const RegionBanner = ({ region, headline, subheadline }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  if(region == null) return null;

  return (
    <Box
      className="urbnups-region-banner"
      sx={{
        position: "relative",
        overflow: "hidden",
        height: {
          xs: 360,

          md: 460,
        },
        backgroundImage: `url(${ResizeHelper.resizeImage(
          region.banner?.image?.url,
          isDesktop ? 1920 : 900
        )})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          // maxWidth:"calc(100% - 40px)",
          bottom: 10,
          padding: 2,
        }}
      >
        <Container
          sx={{
            display: "flex",
          }}
        >
          {region?.banner?.headline != null && (
            <Box
              className="urbnups-region-banner-box"
              sx={{
                overflow: "hidden",
                overflowWrap: "break-word",
              }}
            >
              <Typography
                className="urbnups-region-banner-headline"
                component="div"
                sx={{
                  hyphens: "auto",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                  color: "black",
                }}
              >
                {region?.banner?.headline}
              </Typography>
              {region?.banner?.subheadline != null && (
                <Typography
                  component="div"
                  className="urbnups-region-banner-subheadline"
                  sx={{
                    hyphens: "auto",
                    overflow: "hidden",
                    overflowWrap: "break-word",
                    wordBreak: "break-word",
                    color: "black",
                  }}
                >
                  {region?.banner?.subheadline}
                </Typography>
              )}
            </Box>
          )}
        </Container>
 
      </Box>
      <Box
  className="urbnups-copyright-tooltip"
  sx={{
    position: "absolute",
    bottom: "15px",
    right: "10px",
    display: "flex",
    alignItems: "center",
    zIndex: (window.urbnups?.maxZIndex + 100 ),
    pointerEvents: "auto", // Enable interactions on the tooltip
  }}
>
  <CopyrightTooltip
    text={`${region?.banner?.image?.copyrightName||region?.banner?.image?.source} ${region?.banner?.image?.license || ""}`}
  />
</Box>
    </Box>
  );
};

export default RegionBanner;
