import {
  Button
} from "@mui/material";
const ChatCTAButton = ({ title, message }) => {

  const onSendChatMessage = (message, audio = false) => {
    window.postMessage(
      {
        type: "urbnups-chat-show",
        data: null,
      },
      "*"
    );
    console.log("window urbnups", window.urbnups);
    
    window.postMessage(
      {
        type: "urbnups-chat-sendMessage",
        data: {
          message:  message,
          audio: false,
        },
      },
      "*"
    );
  };

  return (
 <Button onClick={() => onSendChatMessage(message)} variant="contained" className="urbnups-cta-chatbutton" color="primary" >
      {title}
    </Button>
  );
};

export default ChatCTAButton;
