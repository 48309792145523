import { Box, Tab, Tabs } from "@mui/material";
import { styled } from '@mui/material/styles';
import React from "react";
import { useTranslation } from "react-i18next";
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'black',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'black',
  },
});

const getTabValue= (category) => {
  if(category?.type!==null&&category?.key!=="explore")
  {
    return category.type+":"+category?.key;
  }
  return category?.key;
};

const StyledTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),

  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
  color: "black",
            
  "&.Mui-selected": {
    color: "black",
        fontWeight:"bold",
    transition: "background-color 0.5s",
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
  },
}));
const RegionCategoriesSlider = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}) => {
  const {t} = useTranslation();
  const onCategoryClick = (category) => {
    //if same category is clicked, do nothing
   
    //find the category object by splitting the value
    var tmpSelectedCategory=null
    console.log("on category click",category)

    if(category==="explore")
    {
      tmpSelectedCategory = { key: "explore",type:"explore" };
    }
   else if(category.includes(":"))
    {
      category = category.split(":");
      tmpSelectedCategory = categories.find((c) => c?.key === category[1] && c.type === category[0]);
    }
    else
    {
      tmpSelectedCategory = categories.find((c) => c?.key === category);
    }
    setSelectedCategory(tmpSelectedCategory);
  };

  if(categories == null) return null;

  return (
   
     <Box sx={{
      borderBottom: "1px solid #e0e0e0",
     }}>
        <StyledTabs
          className="categories-slider"
          value={getTabValue(selectedCategory)} // Change this to manage selected tab
          variant="scrollable"
          scrollButtons="auto"
         // allowScrollButtonsMobile
        
          onChange={(event, newValue) => {
            onCategoryClick(newValue);
          }}
        >
             <StyledTab
          className="category-tab"
            sx={{
            
            }}
            value={getTabValue({ key: "explore",type:"explore" })}
           
            key={"explore"}
            label={t("region_feed.explore")
            }
          />
         
          {categories.map((category) => (
            <StyledTab
              className="category-tab"
              sx={{
                
               
              }}
              value={getTabValue(category)}
              
              key={category.key}
              label={category.name}
            />
          ))}
        </StyledTabs>
        </Box>
  );
};

export default RegionCategoriesSlider;
