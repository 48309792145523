import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import POIQuestion from "./POIQuestion";
import { useTranslation } from "react-i18next";
const POIQuestionCTA = ({
  questions = [],
  placeholder,
  headline,
  subline,
  place = null,
}) => {
  const [search, setSearch] = useState("");
  const {t} =useTranslation();
  console.log("place",place)
  const onSendChatMessage = (message, audio = false) => {
    window.postMessage(
      {
        type: "urbnups-chat-show",
        data: null,
      },
      "*"
    );
    console.log("window urbnups", window.urbnups);
    var fullmessage=t("place.questionTemplate",{
      placeName:place?.name,
      regionName:place?.geoContext?.city||place?.formattedAddress,
      question:message
    })
    window.postMessage(
      {
        type: "urbnups-chat-sendMessage",
        data: {
          message:fullmessage,
          audio: audio,
        },
      },
      "*"
    );
  };

  return (
    <Box
    
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt:2  
   
      }}
    >
      <Box
        className="urbnups-POIQuestionCTA"
      
      >
        <Typography
          component="h3"
          className="urbnups-poi-cta-banner-headline"
        
        >
          {headline}
        </Typography>
        <Typography
          component="div"
          className="urbnups-poi-cta-banner-subheadline"
      
        >
          {subline}
        </Typography>

        {/* Search Input */}
        <TextField
          fullWidth
          value={search}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              // Do code here
              ev.preventDefault();
              onSendChatMessage(search);
              setSearch("");
            }
          }}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholder}
          InputProps={{
            startAdornment: <SearchIcon />,
            endAdornment: (
              <Button
                variant="contained"
                color="primary"
                sx={{}}
                onClick={() => {
                  onSendChatMessage(search);
                  setSearch("");
                }}
              >
                <SendIcon />
              </Button>
            ),
          }}
          sx={{
            marginBottom: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
        />

        {/* Questions List */}
        <Stack direction="row" flexWrap="wrap" gap={1}>
          {questions.map((question, index) => (
            <POIQuestion
              key={index}
              question={question}
              onClick={onSendChatMessage}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default POIQuestionCTA;
